import 'core-js/es/reflect';
import 'core-js/stable/reflect';
import 'core-js/features/reflect';
import 'zone.js/dist/zone';


if ('production' !== ENV) {
  Error.stackTraceLimit = Infinity;
  // tslint:disable-next-line: no-var-requires
  require('zone.js/dist/long-stack-trace-zone');
}
